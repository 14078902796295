// TODO move this component out to search land to be served in a micro-frontend ecoysystem

const template = document.createElement('template');

template.innerHTML = `<div class='room'>
<!--Topic Name-->
  <div id="backdrop-container"><img id="backdrop"/></div>
  <div id="chat-pane"><span>Enter</span></div>
  <div class='topic_room_info'>
    <h2 class="title"><slot name="title">ROOM TITLE HERE</slot></h2>
    <!--Room Information-->
    <div class='room_information_container'>
      <ul class='room_information'>
        <li id="checkin-container">
          <span class="box-shadow"><i class="fa-solid fa-location-pin"></i> <i class='checkins'><slot name="checkins">0</slot></i> Checkins</span>
        </li>
        <li id="message-container">
          <span class="box-shadow"><i class='far fa-comment-alt'></i> <i class='messageCount'><slot name="messages">0</slot></i> Messages</span>
        </li>
        <li id="group-container">
          <span><i class="fa-solid fa-user-group"></i> <i class='groups'><slot name="groups">1</i> Groups</span>
        </li>
        <li id="talking-container">
          <span class="box-shadow"><i class="fa fa-user" aria-hidden="true"></i> <i class='talking'><slot name="talking">0</slot></i> Talking</span>
        </li>
        <li id="spectator-container">
          <span><i class="fa-solid fa-eye"></i> <i class='subscribers'><slot name="spectating">0</slot></i> Spectators</span>
        </li>
        <br>
        <li id="follow-container" class="toggledOff">
          <div id="follow"><span><i class="fa-solid fa-bell"></i>Follow</span></div>
        </li>
        <li id="share-container" class="toggledOff">
          <div id="share"><span><i class="fa-solid fa-share"></i>Share</span></div>
        </li>
      </ul>
    </div>
  </div>
  <div class="messageContent">
    <p>
      <i class="fa-solid fa-quote-left"></i>
      <span id="messageText"></span>
      <i class="fa-solid fa-quote-right"></i>
    </p>
  </div>
  <!--Topic options-->
  <!-- <div id='topic_details'>
    <ul class='topic_options'>
      <li>
        <div id="spectate">Spectate</div>
      </li>
      <li style="display:none;">
        <div id="join" class="primary">Enter</div>
      </li>
      <li>
        <div id="login" class="primary">Sign up</div>
      </li>
    </ul>
  </div> -->
</div>
<style>
  /* @import url("/css/all.css"); */
  ul.room_information li.toggledOff{
    display:none !important;
  }
  #trending-chat #topic_details #follow{
    display:none;
  }
  .spectateContainer{
    position:absolute;
    top:0px;
    right:-15px;
  }
  .joinContainer{
    position:absolute;
    top:0px;
    left:-15px;
  }

  #follow, #share{
    padding: 3px;
    border: 2px solid;
    border-radius: 10px;
    margin-top: 15px;
    display: inline-block;
    pointer-events: initial;
  }

  #follow:hover, #share:hover{
    cursor:pointer;
    background-color: rgba(var(--color-rgb), .3);
  }

  #follow.following{
    background-color: var(--contrast-color);
    color: var(--color);
  }

  #chat-pane{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow:hidden;
  }

  #chat-pane span{
    transform: translateY(50%);
    height: 100%;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 6vh;
    opacity: 0.25;
    text-transform: uppercase;
  }
  /* .match .spectateContainer{
    position:absolute;
    top:0px;
    right:-55px;
  }
  .match .joinContainer{
    position:absolute;
    top:0px;
    left:-55px;
  } */
  .spectateContainer h3{
    top: 70px;
    left: -70px;
    position: absolute;
    width:200px;
    text-align: center;
    transform: rotate(90deg);
  }
  .joinContainer h3{
    top: 70px;
    right: -70px;
    position: absolute;
    width:200px;
    text-align: center;
    transform: rotate(270deg);
  }
  .spectateContainer h3, .joinContainer h3{
    display:none;
  }
  /* .match .spectateContainer h3, .match .joinContainer h3{
    display:inherit;
  } */
  .spectateContainer div:nth-child(2){
    color:white;
    position:relative;
    right:0px;
  }
  .joinContainer div:nth-child(2){
    position:relative;
    left:0px;
    color:white;
  }
  .spectateContainer div:nth-child(3){
    color:white;
    position:relative;
    right:-25px;
  }
  .joinContainer div:nth-child(3){
    position:relative;
    left:-25px;
    color:white;
  }
  .joinContainer div, .spectateContainer div{
    height:28px;
    width:28px;
    margin: 20px;
    margin-top: 0px;
    border-radius: 48px;
    border: 3px solid white;
  }
  /* .match .joinContainer div, .match .spectateContainer div{
    height:48px;
    width:48px;
    margin: 20px;
    margin-top: 0px;
    border-radius: 48px;
    border: 3px solid white;
    display:inherit;
  } */
  .joinContainer div{
      background: #f47920;
      display: none;
  }
  .spectateContainer div{
    background: linear-gradient(127deg, rgba(255,255,0,1), rgba(255,255,0,0) 100%),
    linear-gradient(336deg, rgba(0,255,255,1), rgba(0,255,255,0) 100%),
    linear-gradient(217deg, rgba(255,0,255,1), rgba(255,0,255,0) 100%);
  }
  /* .match .joinContainer div:hover, .match .spectateContainer div:hover{
    color:#f47920;
    background:white;
  } */
  .room ul li {
    list-style-type: none;
  }
  ul.topic_options{
      list-style-type: none;
      padding-left: 0px;
      margin: 0px;

  }
  ul.topic_options li{
      display: inline-block;
      width: 38%;
      margin-right: 5%;
  }
  ul.topic_options li div{
      text-decoration: none;
      display: block;
      background-color: #f47920;
      /* color: white; */
      /* padding-right: 10px; */
      padding-top: 10px;
      padding-bottom: 10px;
      /* padding-left: 5px; */
      font-weight: bold;
      border-radius: 3px;
      /* margin-right: 5px; */
      font-size: 3.5vh;
      /* color:var(--contrast-color); */
      background-color: rgba(var(--color-rgb), .85);
      border-style: solid;
      border-radius: 5px;
      width:100%;
      text-align:center;
      transition: color 2.25s 0.0833333333s;
      transition: background-color 2.25s 0.0833333333s;
      position:relative;
  }
  ul.topic_options li div:hover{
      /* color: darkorange; */
      color: var(--color);
      background-color: var(--contrast-color);
      cursor:pointer;
  }
  #topic_details{
      position: absolute;
      bottom: 40px;
      width: 100%;
      left:20px;
      /* text-align: center; */
  }
  .room_information_container{
      position: relative;
      top:-20px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      /* bottom: 0px; */
      bottom: -15px;
      margin-left:28px;
      /* background: rgb(255, 255, 255, 50%); */
  }

  .room_information_container #talking-container,
  .room_information_container #spectator-container,
  .room_information_container #group-container{
    display:none;
  }

  .room_information_container #checkin-container,
  .room_information_container #message-container,
  .room_information_container #share-container,
  .room_information_container #follow-container{
    display:inline-block;
    margin-right: 10px;
  }

  .room_information_container.active #talking-container,
  .room_information_container.active #spectator-container,
  .room_information_container.active #group-container{
    display: inline-block;
    margin-right: 10px;
  }

  .room_information_container.active #checkin-container,
  .room_information_container.active #message-container{
    display:none;
  }


  ul.room_information{
      list-style-type: none;
      padding-left: 0px;
  }
  ul.room_information li{
      position:relative;
  }
  ul.room_information li span{
      font-weight: bold;
  }
  .room_container{
      margin-left: 5%;
      margin-top: 10px;
      width: 90%;
      text-align: center;
  }
  .topic_room_info{
      height: 140px;
      position: relative;
      width: 90%;
      pointer-events: none;
  }
  .room h2{
    padding: 20px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    font-size: 6vh;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .room{
      /* width: 175px; */
      /* width: 37vh; */
      min-height: 175px;
      color: #312e2e;
      color: white;
      color: var(--contrast-color);
      margin-bottom: 10px;
      box-shadow: -5px 0px 15px #312e2e;
      position: relative;
      display: inline-block;
      border: 3px solid aqua;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      border: 3px solid var(--color);
      transition: background-image, color, border;
      transition-duration: 3.2s;
      transition-timing-function: ease-in-out;
  }
  .room #chat-pane:hover{
    cursor:pointer;
  }

  .room #chat-pane:hover{
        background-color: rgba(var(--color-rgb),.5);
  }
  .room #backdrop{
    transition: background-image, color, border;
    transition-duration: 3.2s;
    transition-timing-function: ease-in-out;
  }
  .room:before{
    content:"";
    background: linear-gradient(140deg, var(--color), transparent 120%);
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
  }

  .room .room_information{
    font-size: 2vh;
  }
  .room.trendy .room_information{
    display: block;
  }
  .room.trendy #topic_details{
    display:block;
  }
  .room.following{
      border: 3px solid #f47920;
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .primary{
    --angle: 0deg;
    border-style: solid;
    border-radius: 5px;
    border-image: linear-gradient(var(--angle), var(--contrast-color), var(--color)) 1;
    border-width:3px;
    animation: 5s rotate linear infinite;
  }
  .primary:hover{
    animation: 1.5s rotate linear infinite;
  }

  .condensed h2{
    font-size: 18px;
    top: -14px;
    left: -14px;
    position: relative;
  }

  .condensed .room_information_container{
      margin-left: 7px;
      top: -44px;
  }

  .condensed .room_information {
      font-size: 15px;
  }

  .condensed .room_information_container#spectoator-container,
  .condensed .room_information_container.active#spectoator-container{
    display:none;
  }

  .room.condensed #topic_details{
    bottom: inherit;
    left: inherit;
    right: 40px;
    top: 13px;
    width: 210px;
  }

  .condensed ul.topic_options li{
    margin-right:10%;
    width:initial;
  }

  .condensed ul.topic_options li div {
    font-size: 19px;
    height: 25px;
    top: -8px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    background-color: inherit;
    border: 2px solid gray;
    border: solid;
    border: 1px solid gray;
    color:#444444;
  }
  .condensed ul.topic_options li div.primary{
    border-width:2px;
  }

  #backdrop-container{
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    margin-left: 0%;
    margin: 0%;
    height: 100%;
    overflow:hidden;
  }

  .mainstream #backdrop-container{
    display: none;
    width: 80%;
    height: 45%;
    margin-left: 10%;
    margin-top: 10%;
    position:relative;
  }

  .mainstream h2{
    font-size: 3vh;
    margin-left: 10%;
  }

  #backdrop{
    background-size: 100% 100%, cover;
    width: 100%;
    height: 100%;
    border-radius:5px;
    border: 1px solid;
  }

  .room.mainstream{
    box-shadow: inherit;
    border:inherit;
  }

  .room.mainstream:before{
    content:"";
    width:0px;
  }

  .mainstream .room_information_container{
    color:#999
  }

  .mainstream .room_information{
    font-size:1.5vh;
    margin-left:5%;
  }

  .mainstream .topic_room_info{
    height:initial;
  }

  .mainstream #topic_details{
    bottom:inherit;
    position:relative;
    margin-left:10%;
    left:inherit !important;
  }

  .mainstream ul.topic_options li{
    width:inherit;
  }

  .mainstream ul.topic_options li div {
    font-size: 2vh;
    height: 2.1vh;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-rgb), 1);
  }

  .messageContent.hasMessages{
    display:block;
    position:absolute;
    font-size: 3vh;
    width: 81%;
    height: 15vh;
    text-align:vertical;
    overflow: hidden;
    margin-left: 5%;
    bottom: 20px;
    pointer-events:none;
    /* bottom: calc(80px + 3.5vh); */
  }
  .messageContent{
    display:none;
  }

  .messageContent p{
    position:absolute;
    bottom:0px;
    margin-top:0px;
    margin-bottom:0px;
    padding-left: 2vh;
    padding-right: 2vh;
    background: var(--color);
    background-color: rgba(var(--color-rgb), .85);
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .messageContent p a{
    color: var(--contrast-color);
  }

  #messageText{
    max-height: 16vh;
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .room{
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 580px){
    .room{
      width: 100%;
      height: 100%;
    }
    #topic_details{
        bottom: 20px;
    }
  }

  @media screen and (min-width: 620px){
    .room h2{
      font-size:4vh;
    }
    ul.topic_options li div{
      font-size:2.5vh;
    }
    .messageContent.hasMessages{
      font-size: 2.5vh;
      width: 81%;
      height: 9vh;
      bottom: 20px;
      /* bottom: calc(60px + 2.5vh); */
    }
    .messageContent p{
      -webkit-line-clamp: 2;
    }
  }

  @media screen and (min-width: 750px){
    .room{
      width: 95%;
      height: 95%;
      margin-left: 2.5%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
      box-shadow: -4px 0px 12px #312e2e
    }
    .room#trending-chat{
      height:88%;
    }
    .messageContent.hasMessages{
      font-size: 2.5vh;
      width: 83%;
      height: 21vh;
      bottom: 20px;
      /* bottom: calc(70px + 2.5vh); */
    }
    .messageContent p{
      -webkit-line-clamp: 7;
    }
  }

  @media (min-width:1060px){
    .room#trending-chat .room_information_container{
      top: 0px;
      width: 38%;
      height: 0px;
      right: 15px;
      position: absolute;
      text-align: right;
    }
    .room#trending-chat .topic_room_info{
      width:100%;
    }
    .room{
      height:88%;
    }
    .room#trending-chat{
      height:78%;
    }
    .messageContent.hasMessages{
      font-size: 2.5vh;
      width: 83%;
      height: 9vh;
      bottom: 20px;
      /* bottom: calc(60px + 2.5vh); */
    }
    .messageContent p{
      -webkit-line-clamp: 3;
    }
  }

</style>`;

/**
 * Custom Web Component for the nodes that appear on the search results page
 */
class TopicElement extends HTMLElement {
  /**
   * Simple constructor for TopicElement
   */
  constructor() {
    super();

    this.backgroundIndex = 0;
    this.messageIndex = 0;
    this.messages = [];
    this.images = [];
    this.links = [];
    this.following = false;

    // const template = document.getElementById('topic-room');
    const templateContent = template.content;

    this.attachShadow({mode: 'open'})
        .appendChild(templateContent.cloneNode(true));
  }

  /**
   * baked in method that defines the attributes that matter to the international
   * workings of this component
   */
  static get observedAttributes() {
    return [
      'id',
      'data-authenticated',
      'topic',
      'color',
      'data-server',
      'data-center',
      'followers',
      'data-following',
      // 'talkers',
      // 'spectators',
      // 'messageCount',
      'messages',
      // 'groups',
      // 'checkins',
      'title',
      'follow-status',
      // 'primary',
      // 'trendy',
      'weight',
      'background',
      'condensed',
      'mainstream',
    ];
  }

  /**
   * Handler for changes to attributes
   * @param {string} name
   * @param {string} oldValue
   * @param {string} newValue
   */
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'messages':
        this.messages = JSON.parse(newValue);
        break;
      case 'data-following':
        this.following = newValue == 'true';
        break;
      // case 'topic':
      //   this.topic = newValue;
      //   break;
      default:
        break;
    }
    this.updateStyle();
  }

  /**
   * Handler for changes to follow status
   * @param {Object} btnClickEvent
   */
  toggleFollowStatus(btnClickEvent) {
    if (FEATURE_TOGGLES.roomSubscriptions === true) {
      btnClickEvent.stopPropagation();
      const followBtn = btnClickEvent.target;
      const followBtnParent = followBtn.parentNode;
      // console.log(followBtn);
      // console.log(followBtn.parentNode);
      // console.log(followBtn.parentElement);

      const wasFollowed = this.getAttribute('data-following') === 'true';
      // console.log(this);
      // console.log(this.getAttribute('topic'));
      const topic = this.getAttribute('topic');
      // console.log(topic);

      const xmlhttp = new XMLHttpRequest();
      if (this.getAttribute('data-following') === 'true') {
        xmlhttp.open('POST', `/lounge/unregister_subscriber/${topic}`, true);
        this.setAttribute('data-following', false);
        // console.log(followBtn);
        // console.log(followBtn.parentNode);
        // console.log(followBtn.parentElement);
        // followBtnParent.classList.remove('following');
      } else {
        xmlhttp.open('POST', `/lounge/register_subscriber/${topic}`, true);
        this.setAttribute('data-following', true);
        // console.log(followBtn);
        // console.log(followBtn.parentNode);
        // console.log(followBtn.parentElement);
        // followBtnParent.classList.add('following');
      }
      xmlhttp.send();
      xmlhttp.onloadend=function() {
        // const roomDOMObject = document.getElementById('#follow');
        // roomDOMObject.parentNode.classList.add('following');

        if (wasFollowed) {
          followBtn.innerHTML = '<i class="fa-solid fa-bell-on"></i>Follow';
        } else {
          followBtn.innerHTML = '<i class="fa-solid fa-bell-on"></i>Following';
        }
        // const subCount = parseInt(roomDOMObject.parentElement.getElementsByClassName('subscribers')[0].innerHTML);
        // roomDOMObject.parentElement.getElementsByClassName('subscribers')[0].innerHTML = subCount+1;
        // showMessageDialog({heading: 'Success', message: 'You are now following! What does this mean? You\'ll get emailed the every time another Talkduster joins this room.'});
      };
      xmlhttp.onerror=function() {
        showErrorDialog({message: 'Something went wrong following'});
      };
    }
  }

  /**
   * inject stylesheet (URL) to be accessed in shadow dom
   * TODO - seems contradictory to shadow dom... perhaps the js and html
   * for messages needs to be it's own web component and included in here
   * and in the chat.js file.
   * @param {string} styleURL - reference to *.css file
   */
  injectStyleSheet(styleURL) {
    const style = document.createElement('style');
    style.type = 'text/css';
    // style.styleSheet.cssText =`@import url("${styleURL}");`
    style.appendChild(document.createTextNode(`@import url("${styleURL}");`));
    this.shadowRoot.appendChild(style);
  }

  /**
   * called everytime a component is attached to the DOM
   */
  connectedCallback() {
    // it's possible a component is connected, and by the time this method is
    // called, that it's disconected, sp we verify it is connected
    if (!this.isConnected) {
      return;
    }

    this.classList.add('authentication');

    if (typeof FontAwesome !== 'undefined') {
      FontAwesome.dom.i2svg({
        node: this.shadowRoot,
      });
      FontAwesome.dom.watch({
        autoReplaceSvgRoot: this,
        observeMutationsRoot: this.shadowRoot,
      });
    }

    // TODO set private default values in constructor and not set attributes
    // to said defaults below -- do so after implementing unit-tests and
    // an additional system level tests that demonstrates images/messages shared
    // in a room appear on search results.
    // if (!this.hasAttribute('data-authenticated')) {
    //   this.setAttribute('data-authenticated', false);
    // }
    if (!this.hasAttribute('data-topic')) {
      this.setAttribute('data-topic', '');
    }
    if (!this.hasAttribute('data-server')) {
      this.setAttribute('data-server', '');
    }
    if (!this.hasAttribute('data-center')) {
      this.setAttribute('data-center', '');
    }
    if (!this.hasAttribute('data-followers')) {
      this.setAttribute('data-followers', 0);
    }

    if (!this.hasAttribute('data-following')) {
      this.setAttribute('data-following', 'false');
    } else if (this.getAttribute('data-following') === 'true') {
      this.following = true;
    }

    if (!this.hasAttribute('title')) {
      this.setAttribute('title', this.getAttribute('data-topic'));
    }
    if (!this.hasAttribute('follow-status')) {
      this.setAttribute('follow-status', false);
    }
    if (!this.hasAttribute('color')) {
      this.setAttribute('color', '#f47920');
    }
    if (!this.hasAttribute('weight')) {
      this.setAttribute('weight', '200');
    }

    // this.shadowRoot.querySelector('div#join').onclick = joinEvent => {
    this.shadowRoot.querySelector('.room').onclick = joinEvent => {
      utilityObj.joinConversation(this.getAttribute('id'), this.getAttribute('data-server'), this.getAttribute('data-center'));
      joinEvent.preventDefault();
    };

    this.rotateContent();
    this.updateStyle();

    setInterval(() => {
      this.rotateContent();
    }, 10000);
  }

  /**
   * takes care of modifying the dom with new values
   */
  updateStyle() {
    if (FEATURE_TOGGLES.roomSubscriptions === true && this.getAttribute('data-authenticated') == 'true') {
      this.shadowRoot.querySelector('#follow').parentNode.classList.remove('toggledOff');
      if (this.following) {
        this.shadowRoot.querySelector('#follow').innerHTML = '<i class="fa-solid fa-bell-on"></i>Following';
      }
      this.shadowRoot.querySelector('#follow').onclick = eventObj => {
        // ask for permission to push notify
        utilityObj.checkToDisplayNotificationPrompt();
        this.toggleFollowStatus(eventObj);
      };
    } else {
      this.shadowRoot.querySelector('#follow').parentNode.classList.add('toggledOff');
    }

    if (navigator.canShare) {
      const topic = this.getAttribute('topic');
      const title = this.getAttribute('title');
      this.shadowRoot.querySelector('#share').parentNode.classList.remove('toggledOff');
      this.shadowRoot.querySelector('#share').onclick = async event => {
        event.stopPropagation();
        await navigator.share({
          title: `Talkdust - ${title}`,
          text: `Join me in a conversation about ${title} over at Talkdust!`,
          url: `${window.location.href}chat/rooms/${topic}`,
        });
      };
    }
    this.shadowRoot.children[0].id =this.getAttribute('id');
    // this.shadowRoot.querySelector('.talking').innerText = this.getAttribute('talkers');
    if (this.shadowRoot.querySelector('.talking').innerText != '0') {
      this.shadowRoot.querySelector('.room_information_container').classList.add('active');
    } else {
      this.shadowRoot.querySelector('.room_information_container').classList.remove('active');
    }
    // this.shadowRoot.querySelector('.messageCount').innerText = this.getAttribute('messageCount');
    // this.shadowRoot.querySelector('.subscribers').innerText = this.getAttribute('spectators');
    // this.shadowRoot.querySelector('.checkins').innerText = this.getAttribute('checkins');
    // this.shadowRoot.querySelector('.groups').innerText = this.getAttribute('groups');
    this.shadowRoot.querySelector('.title').innerText = this.getAttribute('title');

    // get rid of these magic numbers and reference container dimensions
    let startingFontSize = Math.floor(6 * window.innerHeight / 100);
    // let startingFontSize = Math.floor(this.shadowRoot.querySelector('.title').parentNode.getBoundingClientRect().height);
    const targetHeight = Math.floor(6 * window.innerHeight / 100) + 40;
    // let targetHeight = this.shadowRoot.querySelector('.title').parentNode.getBoundingClientRect().height;
    // let targetWidth = Math.floor(24.7 * window.innerHeight / 100) - 40;
    const targetWidth = this.shadowRoot.querySelector('.title').parentNode.parentNode.getBoundingClientRect().width;
    // if (window.innerWidth >= 750) {
    //   // let targetHeight = Math.floor(6 * window.innerHeight / 100) + 40;
    //   targetWidth = Math.floor(56 * window.innerHeight / 100) - 40;
    // }
    // const desired_height = 100 * px / window.innerHeight;

    // console.log( this.shadowRoot.querySelector('.title').offsetHeight / window.innerHeight * 100, startingFontSize);
    // console.log( this.shadowRoot.querySelector('.title').scrollWidth, targetWidth);

    while (this.shadowRoot.querySelector('.title').offsetHeight > targetHeight && startingFontSize > 5) {
      startingFontSize--;
      // console.log(
      //   targetHeight,
      //   startingFontSize,
      //   this.shadowRoot.querySelector('.title').offsetHeight,
      //   this.shadowRoot.querySelector('.title').offsetHeight > targetHeight && startingFontSize > 5
      // );
      this.shadowRoot.querySelector('.title').style.fontSize = `${startingFontSize}px`;
    }

    while (this.shadowRoot.querySelector('.title').scrollWidth > targetWidth && startingFontSize > 5) {
      startingFontSize--;
      // console.log(
      //   targetWidth,
      //   startingFontSize,
      //   this.shadowRoot.querySelector('.title').scrollWidth,
      //   this.shadowRoot.querySelector('.title').scrollWidth > targetWidth && startingFontSize > 5
      // );
      this.shadowRoot.querySelector('.title').style.fontSize = `${startingFontSize}px`;
    }
    // if (this.getAttribute('primary') == 'true') {
    //   this.shadowRoot.querySelector('.room').classList.add('match');
    // } else {
    //   this.shadowRoot.querySelector('.room').classList.remove('match');
    // }

    // console.log(this.hasAttribute('messageCount'));
    // console.log(parseInt(this.getAttribute('messageCount')))
    if (this.shadowRoot.querySelector('.messageCount').innerText != '0') {
    // if (this.hasAttribute('messageCount') && parseInt(this.getAttribute('messageCount')) > 0) {
      this.shadowRoot.querySelector('.messageContent').classList.add('hasMessages');
    } else {
      this.shadowRoot.querySelector('.messageContent').classList.remove('hasMessages');
    }

    if (this.hasAttribute('condensed')) {
      this.shadowRoot.querySelector('.room').classList.add('condensed');
    } else {
      this.shadowRoot.querySelector('.room').classList.remove('condensed');
    }

    if (this.hasAttribute('mainstream')) {
      this.shadowRoot.querySelector('.room').classList.add('mainstream');
    } else {
      this.shadowRoot.querySelector('.room').classList.remove('mainstream');
    }

    // if (this.getAttribute('trendy') == 'true') {
    //   this.shadowRoot.querySelector('.room').classList.add('trendy');
    // } else {
    //   this.shadowRoot.querySelector('.room').classList.remove('trendy');
    // }
    let targetContrast = 'white';
    if (typeof this.getAttribute('color') === 'string' && this.getAttribute('color').startsWith('#')) {
      targetContrast = this.determineTitleColor(this.getAttribute('color'));
    }
    if (!this.shadowRoot.children[0].style['--contrast-color']) {
      this.shadowRoot.children[0].style.setProperty('--contrast-color', targetContrast);
    }

    if (this.getAttribute('color') !== null) {
      this.shadowRoot.children[0].style.setProperty('--color', this.getAttribute('color'));
      this.shadowRoot.children[0].style.setProperty('--color-rgb', this.hexToRGB(this.getAttribute('color')));
    } else {

    }

    // this.shadowRoot.querySelector('.followBtn').onclick = toggleFollowStatus
  }

  /**
   * Rotate content that would be observed in the chat room to give preview
   */
  rotateContent() {
    if (typeof this._interalTicker === 'undefined') {
      this._interalTicker = 0;
    }
    if (this._interalTicker === 0) {
      this.rotateBGImage();
    }
    this.rotateMessage();
    this._interalTicker++;
    if (this._interalTicker > 2) {
      this._interalTicker = 0;
    }
  }

  /**
   * Rotates through recent messages
   */
  rotateMessage() {
    if (this.messages.length == 0) {
      return;
    }

    if (this.messageIndex+1 >= this.messages.length) {
      this.messageIndex = 0;
    } else {
      this.messageIndex++;
    }

    const message = this.messages[this.messageIndex].data;
    let text = message.text;
    if (message.links instanceof Array) {
      message.links.forEach(link => {
        const urlObj = new URL(link);
        const replacement = `<a href=${link}>${urlObj.host}</a>`;
        text = text.replace(link, replacement);
      });
    }

    this.shadowRoot.querySelector('#messageText').innerHTML = text;
  }

  /**
   * logic for rotating the topic-rooms background image based on links
   * and uploaded images
   */
  rotateBGImage() {
    let count = 0;

    if (this.images instanceof Array && this.images.length > 0) {
      count += this.images.length;
    }
    if (this.links instanceof Array && this.links.length > 0) {
      count += this.links.length;
    }
    let match = false;
    while (count-- > 0 && !match) {
      if (this.backgroundIndex+1 >= (this.images.length + this.links.length)) {
        this.backgroundIndex = 0;
      } else {
        this.backgroundIndex++;
      }
      let index; let key;
      if (this.backgroundIndex < this.images.length) {
        index = this.backgroundIndex;
        key = this.images[index];

        // TODO eliminate the need for this component to be aware of uploadedImages
        match = true;//
        this.setBGImage(key);
      } else {
        index = this.backgroundIndex - this.images.length;
        key = this.links[index];

        // TODO eliminate the need for this component to be aware of uploadedImages
        match = true;
        this.setBGImage(key);
      }
    }
  }

  /**
   * takes care of setting the appropriate attributes and properties
   * for a given backgroundOmg
   * @param {object} imgObj - includes a base64 string and color theme
   */
  async setBGImage(imgObj) {
    this.shadowRoot.querySelector('#backdrop-container').style.display='block';
    this.shadowRoot.querySelector('#backdrop').style.backgroundImage = `url("${imgObj.imgb64}")`;
    this.shadowRoot.querySelector('#backdrop').style.backgroundSize = `cover`;
    this.shadowRoot.querySelector('#backdrop').style.backgroundPosition = `center center`;
    if (typeof imgObj.theme === 'object' && imgObj.theme.bgColor != '#aN' && imgObj.theme.textColor != '#aN') {
      this.setAttribute('color', imgObj.theme.bgColor);
    } else {
      const analyse = new ColorAnalyzer();
      imgObj.theme = await analyse.loadCanvas(imgObj.imgb64);
    }
    this.shadowRoot.children[0].style.setProperty('--color', imgObj.theme.bgColor);
    this.shadowRoot.children[0].style.setProperty('--color-rgb', this.hexToRGB(imgObj.theme.bgColor));
    this.shadowRoot.children[0].style.setProperty('--contrast-color', imgObj.theme.textColor);
  }

  /**
   * takes care of setting the appropriate attributes and properties
   * for a given backgroundOmg
   * @param {object} imgObj - includes a base64 string and color theme
   */
  addBGImage(imgObj) {
    // title === link
    if (typeof imgObj !== 'object') {
      return;
    }

    if (typeof imgObj.title !== 'undefined') {
      this.links.push(imgObj);
    } else {
      this.images.push(imgObj);
    }

    if (this.links.length + this.images.length === 1) {
      this.setBGImage(imgObj);
    }
  }

  /**
   * Utility fnction, takes 6 digit hexadecimal and returns white or black
   * @param {string} color - hexadecimal color
   * @return {string} - 'black' or 'white'
   */
  determineTitleColor(color) {
    // TODO find stackoverflow and give credit for this
    const red = parseInt(color.slice(1, 3), 16);
    const green = parseInt(color.slice(3, 5), 16);
    const blue = parseInt(color.slice(5, 7), 16);

    // Counting the perceptive luminance - human eye favors green color...
    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue)/255;

    if (luminance > 0.5) {
      return 'white';
    }
    return 'black';
  }

  /**
   * CSS focused, turns hex into RGB syntax
   * @param {string} hexString - hexadecimal color
   * @return {string} - RGB string CSS syntax
   */
  hexToRGB(hexString) {
    if (hexString.length != 4 && hexString.length != 7) {
      console.warn(`Passed color (${hexString}) to hexToRGB must be hexadecimal`);
      return `0,0,0`;
    }

    let red; let green; let blue;

    if (hexString.length == 4) {
      red = parseInt(`${hexString.slice(1, 2)}${hexString.slice(1, 2)}`, 16);
      green = parseInt(`${hexString.slice(2, 3)}${hexString.slice(2, 3)}`, 16);
      blue = parseInt(`${hexString.slice(3, 4)}${hexString.slice(3, 4)}`, 16);
    }
    if (hexString.length == 7) {
      red = parseInt(hexString.slice(1, 3), 16);
      green = parseInt(hexString.slice(3, 5), 16);
      blue = parseInt(hexString.slice(5, 7), 16);
    }
    return `${red}, ${green}, ${blue}`;
  }

  /**
   * CSS focused, turns hex into HSL syntax
   * @param {string} color - hexadecimal color
   * @return {string} - HSL string CSS syntax
   */
  hexToHSL(color) {
    // Convert hex to RGB first
    let red = 0;
    let green = 0;
    let blue = 0;
    if (hexString.length == 4) {
      red = `0x${ hexString[1] }${hexString[1]}`;
      green = `0x${ hexString[2] }${hexString[2]}`;
      blue = `0x${ hexString[3] }${hexString[3]}`;
    } else if (hexString.length == 7) {
      red = `0x${ hexString[1] }${hexString[2]}`;
      green = `0x${ hexString[3] }${hexString[4]}`;
      blue = `0x${ hexString[5] }${hexString[6]}`;
    }
    // Then to HSL
    red /= 255;
    green /= 255;
    blue /= 255;
    const cmin = Math.min(red, green, blue);
    const cmax = Math.max(red, green, blue);
    const delta = cmax - cmin;
    let hue = 0;
    let saturation = 0;
    let lightness = 0;

    if (delta == 0) {
      hue = 0;
    } else if (cmax == red) {
      hue = ((green - blue) / delta) % 6;
    } else if (cmax == green) {
      hue = (blue - red) / delta + 2;
    } else {
      hue = (red - green) / delta + 4;
    }

    hue = Math.round(hue * 60);

    if (hue < 0) {
      hue += 360;
    }

    lightness = (cmax + cmin) / 2;
    saturation = delta == 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));
    saturation = +(saturation * 100).toFixed(1);
    lightness = +(lightness * 100).toFixed(1);

    return {
      hue,
      saturation,
      lightness,
    };
    return `hsl(${ hue },${ saturation }%,${ lightness }%)`;
  }
}

export default TopicElement;

customElements.define('topic-room', TopicElement);

/**
Constructor for ColorAnalyzer
@param {Object} imgReference
@param {number} maxColorBits
*/
function ColorAnalyzer(imgReference, maxColorBits) {
  if (maxColorBits === null || typeof maxColorBits === 'undefined') {
    maxColorBits = 8;
  }
  this.octree = new Octree(maxColorBits);
  this.imgReference = imgReference;
  // this.loadCanvas(img, canvas);
}

ColorAnalyzer.prototype = Object.create(Object);
ColorAnalyzer.prototype.loadCanvas = function(imgb64) {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      this.imgWidth = img.width;
      this.imgHeight = img.height;
      canvas.width = this.imgWidth;
      canvas.height = this.imgHeight;
      context.drawImage(img, 0, 0);
      this.imageData = context.getImageData(0, 0, this.imgWidth, this.imgHeight);
      const bgColor = this.detectBackground();
      const palette = this.analyseImage(6, bgColor);
      const colorPalette = palette[0].map(value => `#${ ((1 << 24) + (Math.round(value[0][0]) << 16) + (Math.round(value[0][1]) << 8) + Math.round(value[0][2])).toString(16).slice(1)}`);
      const textColor = this.chooseTextColor(bgColor, palette[0]);
      resolve({
        bgColor: `#${ ((1 << 24) + (bgColor[0] << 16) + (bgColor[1] << 8) + bgColor[2]).toString(16).slice(1)}`,
        colors: colorPalette,
        textColor: `#${ ((1 << 24) + (textColor[0] << 16) + (textColor[1] << 8) + textColor[2]).toString(16).slice(1)}`,
      });
    };
    img.src = imgb64;
  });
};
ColorAnalyzer.prototype.loadImage = async function(data) {
  if (typeof data === 'undefined') {
    this.img = sharp(this.imgReference);
  } else {
    const buff = Buffer.from(data, 'base64');
    this.img = sharp(Buffer.from(data, 'base64'));
  }
  this.metaData = await this.img.metadata();

  this.imgWidth = this.metaData.width;
  this.imgHeight = this.metaData.height;

  this.imageData = await this.img
      .raw({depth: 'uint'})
      .toBuffer({resolveWithObject: true});

  return this.imageData;
};
ColorAnalyzer.prototype.getPixel = function(x, y, channels) {
  let channel; let _i;
  if (channels === null || typeof channels === 'undefined') {
    channels = 3;
  }
  const idx = (y * this.imgWidth + x) * 4;
  const _results = [];
  channel = 0;
  _i = 0;
  for (; 0 <= channels ? _i < channels : _i > channels; channel = 0 <= channels ? ++_i : --_i) {
    _results.push(this.imageData.data[idx + channel]);
  }

  return _results;
};
ColorAnalyzer.prototype.detectBackground = function() {
  // let metaData = await sharpImg.metadata();

  let bgColor;
  let color;
  const colors = [];
  const colorFreqs = {};
  let freq;
  let mostFrequent = -1;

  // top and bottom row
  for ( let pixelX = 0; pixelX < this.imgWidth; pixelX++) {
    const colorTop = this.getPixel(pixelX, 0);
    if (typeof colorFreqs[colorTop] === 'number') {
      colorFreqs[colorTop]++;
    } else {
      if (colorTop == ',,') {
        // continue;
      } else {
        colors.push(colorTop);
        colorFreqs[colorTop] = 1;
      }
    }
    const colorBottom = this.getPixel(pixelX, this.imgHeight - 1);
    if (typeof colorFreqs[colorBottom] === 'number') {
      colorFreqs[colorBottom]++;
    } else {
      if (colorBottom == ',,') {
        // continue;
      } else {
        colors.push(colorBottom);
        colorFreqs[colorBottom] = 1;
      }
    }
  }

  // first and last column
  for ( let pixelY = 0; pixelY < this.imgHeight; pixelY++) {
    const colorFirst= this.getPixel(0, pixelY);
    if (typeof colorFreqs[colorFirst] === 'number') {
      colorFreqs[colorFirst]++;
    } else {
      if (colorFirst == ',,') {
        // continue;
      } else {
        colors.push(colorFirst);
        colorFreqs[colorFirst] = 1;
      }
    }
    const colorLast = this.getPixel(this.imgWidth - 1, pixelY);
    if (typeof colorFreqs[colorLast] === 'number') {
      colorFreqs[colorLast]++;
    } else {
      if (colorLast == ',,') {
        // continue;
      } else {
        colors.push(colorLast);
        colorFreqs[colorLast] = 1;
      }
    }
  }

  for (let colorIndex = 0; colorIndex < colors.length; colorIndex++) {
    color = colors[colorIndex];
    freq = colorFreqs[color];
    if (freq > mostFrequent) {
      bgColor = color;
      mostFrequent = freq;
    }
  }
  return bgColor;
};
ColorAnalyzer.prototype.detectBackground = function() {
  let bgColor; let color; let freq; let mostFrequent; let x; let y; let _i; let _len;
  const top = (function() {
    let _i; let _ref;
    const _results = [];
    x = 0;
    _i = 0;
    for (_ref = this.imgWidth; 0 <= _ref ? _i < _ref : _i > _ref; x = 0 <= _ref ? ++_i : --_i) {
      _results.push(this.getPixel(x, 0));
    }
    return _results;
  }).call(this);
  const bottom = (function() {
    let _i; let _ref;
    const _results = [];
    x = 0;
    _i = 0;
    for (_ref = this.imgWidth; 0 <= _ref ? _i < _ref : _i > _ref; x = 0 <= _ref ? ++_i : --_i) {
      _results.push(this.getPixel(x, this.imgHeight - 1));
    }
    return _results;
  }).call(this);
  const left = (function() {
    let _i; let _ref;
    const _results = [];
    y = 0;
    _i = 0;
    for (_ref = this.imgHeight; 0 <= _ref ? _i < _ref : _i > _ref; y = 0 <= _ref ? ++_i : --_i) {
      _results.push(this.getPixel(0, y));
    }
    return _results;
  }).call(this);
  const right = (function() {
    let _i; let _ref;
    const _results = [];
    y = 0;
    _i = 0;
    for (_ref = this.imgHeight; 0 <= _ref ? _i < _ref : _i > _ref; y = 0 <= _ref ? ++_i : --_i) {
      _results.push(this.getPixel(this.imgWidth - 1, y));
    }
    return _results;
  }).call(this);
  const border = ((top.concat(bottom)).concat(left)).concat(right);
  const colorFreqs = {};
  for (_i = 0, _len = border.length; _i < _len; _i++) {
    color = border[_i];
    if (colorFreqs[color.toString()] !== null) {
      colorFreqs[color.toString()]++;
    } else {
      colorFreqs[color.toString()] = 1;
    }
  }
  bgColor = top[0];
  mostFrequent = 0;
  for (color in colorFreqs) {
    if (Object.prototype.hasOwnProperty.call(colorFreqs, color)) {
      freq = colorFreqs[color];
      if (freq > mostFrequent) {
        bgColor = color.split(',').map(function(x) {
          return parseInt(x);
        });
        mostFrequent = freq;
      }
    }
  }
  return bgColor;
};
ColorAnalyzer.prototype.rgbToHsl = function(red, green, blue) {
  let hue;
  let saturation;
  red /= 255;
  green /= 255;
  blue /= 255;
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  const lightness = (max + min) / 2;
  if (max === min) {
    hue = 0;
    saturation = 0;
  } else {
    const delta = max - min;
    saturation = lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min);
    switch (max) {
      case red:
        hue = (green - blue) / delta + (green < blue ? 6 : 0);
        break;
      case green:
        hue = (blue - red) / delta + 2;
        break;
      case blue:
        hue = (red - green) / delta + 4;
        break;
      default:
        break;
    }
    hue /= 6;
  }
  return [hue, saturation, lightness];
};
ColorAnalyzer.prototype.hslToRgb = function(hue, saturation, lightness) {
  let porque; let delta;
  let red = lightness;
  let green = lightness;
  let blue = lightness;
  if (saturation !== 0) {
    // originally was p, q, t -- not certain what p q or t stood for
    // most resources I saw had arbitrary V and C values, which also don't
    // help too much for better variable naming
    const hue2rgb = function(porque, delta, rotation) {
      if (rotation < 0) {
        rotation += 1;
      }
      if (rotation > 1) {
        rotation -= 1;
      }
      if (rotation < 1 / 6) {
        return porque + (delta - porque) * 6 * rotation;
      }
      if (rotation < 1 / 2) {
        return delta;
      }
      if (rotation < 2 / 3) {
        return porque + (delta - porque) * (2 / 3 - rotation) * 6;
      }
      return porque;
    };
    delta = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
    porque = 2 * lightness - delta;
    red = hue2rgb(porque, delta, hue + 1 / 3);
    green = hue2rgb(porque, delta, hue);
    blue = hue2rgb(porque, delta, hue - 1 / 3);
  }
  return [red * 255, green * 255, blue * 255];
};
ColorAnalyzer.prototype.chooseTextColor = function(backgroundColor, palette) {
  let color; let count; let hue; let lightness; let lerp; let modeColor; let modeCount; let saturation; let _i; let _len; let _ref1; let _ref2; let _ref3;
  if (palette === null) {
    palette = null;
  }
  const red = backgroundColor[0];
  const green = backgroundColor[1];
  const blue = backgroundColor[2];
  const _ref = this.rgbToHsl(red, green, blue);
  hue = _ref[0];
  saturation = _ref[1];
  lightness = _ref[2];
  hue += 0.5;
  if (hue > 1) {
    hue -= 1;
  }
  saturation = (1 - saturation) * 0.25;
  lightness = 1 - lightness;
  if (lightness < 0.5) {
    lightness = -lightness + 0.5;
  } else if (lightness > 0.5) {
    lightness = -lightness + 1.5;
  } else {
    lightness = 1;
  }
  if (palette !== null) {
    lerp = function(t, from, to) {
      return t * to + (1 - t) * from;
    };
    _ref1 = palette[0];
    modeColor = _ref1[0];
    modeCount = _ref1[1];
    for (_i = 0, _len = palette.length; _i < _len; _i++) {
      _ref2 = palette[_i];
      color = _ref2[0];
      count = _ref2[1];
      if (count > modeCount) {
        modeCount = count;
        modeColor = color;
      }
    }
    const mRed = modeColor[0];
    const mGreen = modeColor[1];
    const mBlue = modeColor[2];
    _ref3 = this.rgbToHsl(mRed, mGreen, mBlue);
    const mHue = _ref3[0];
    const mSaturation = _ref3[1];
    const mLightness = _ref3[2];
    saturation = Math.min(saturation, mSaturation);
    hue = lerp(0.75, hue, mHue);
  }
  const rgb = (this.hslToRgb(hue, saturation, lightness)).map(Math.floor);
  return rgb;
};
ColorAnalyzer.prototype.analyseImage = function(numClusters, excludedColor = this.detectBackground(), ignoreGrey = false) {
  const threshold = 1; // appeared to be a magic number?
  const paletteSize = 1024; // appeared to be a magic number?
  const error = 32; // appeared to be a magic number?

  let cluster;
  const _ref = this.getThresholdedPalette(threshold, paletteSize, excludedColor, error, ignoreGrey);
  const palette = _ref[0];
  const numVectors = _ref[1];
  const clusterer = new KMeans(numClusters, 3);
  clusterer.setPoints(palette);
  const clusters = clusterer.performCluster();
  const colors = [];
  let _i; let _len;
  for (_i = 0, _len = clusters.length; _i < _len; _i++) {
    cluster = clusters[_i];
    colors.push([cluster.getMean(), cluster.size]);
  }

  return [colors, numVectors];
};
ColorAnalyzer.prototype.getClusteredPalette = function(numClusters, threshold, paletteSize, exclude, error, ignoreGrey) {
  let cluster;
  const _ref = this.getThresholdedPalette(threshold, paletteSize, exclude, error, ignoreGrey);
  const palette = _ref[0];
  const numVectors = _ref[1];
  const clusterer = new KMeans(numClusters, 3);
  clusterer.setPoints(palette);
  const clusters = clusterer.performCluster();
  const colors = (function() {
    let _i; let _len;
    const _results = [];
    for (_i = 0, _len = clusters.length; _i < _len; _i++) {
      cluster = clusters[_i];
      _results.push([cluster.getMean(), cluster.size]);
    }
    return _results;
  })();
  return [colors, numVectors];
};
ColorAnalyzer.prototype.getThresholdedPalette = function(threshold, paletteSize, exclude, error, ignoreGrey) {
  let color; let sum; let _i; let _len;
  const _ref = this.getPalette(paletteSize, exclude, error, ignoreGrey);
  const colors = _ref[0];
  const numVectors = _ref[1];
  colors.sort(function(color1, color2) {
    return color2[1] - color1[1];
  });
  const newColors = [];
  sum = 0;
  for (_i = 0, _len = colors.length; _i < _len; _i++) {
    color = colors[_i];
    newColors.push(color);
    sum += color[1];
    if (sum > (numVectors * threshold)) {
      break;
    }
  }
  return [newColors, numVectors];
};
ColorAnalyzer.prototype.getFilteredPalette = function(stdDeviations, paletteSize, exclude, error, ignoreGrey) {
  let color; let freq; let freqSum; let i;
  let meanDiff; let numColors;
  let stdDevSum; let _i; let _jIndex; let _ref1; let _ref2; let _ref3; let _ref4;
  const _ref = this.getPalette(paletteSize, exclude, error, ignoreGrey);
  const colors = _ref[0];
  const numVectors = _ref[1];
  numColors = 0;
  freqSum = 0;
  i = 0;
  _i = 0;
  for (_ref1 = colors.length; 0 <= _ref1 ? _i < _ref1 : _i > _ref1; i = 0 <= _ref1 ? ++_i : --_i) {
    _ref2 = colors[i];
    color = _ref2[0];
    freq = _ref2[1];
    freqSum += freq;
    numColors++;
  }
  const meanFrequency = freqSum / numColors;
  stdDevSum = 0;
  i = 0;
  _jIndex = 0;
  for (_ref3 = colors.length; 0 <= _ref3 ? _jIndex < _ref3 : _jIndex > _ref3; i = 0 <= _ref3 ? ++_jIndex : --_jIndex) {
    _ref4 = colors[i];
    color = _ref4[0];
    freq = _ref4[1];
    meanDiff = freq - meanFrequency;
    stdDevSum += meanDiff * meanDiff;
  }
  const stdDevFrequency = Math.sqrt(stdDevSum / numColors);
  const filteredColors = (function() {
    let _kIndex; let _len; let _ref5;
    const _results = [];
    for (_kIndex = 0, _len = colors.length; _kIndex < _len; _kIndex++) {
      _ref5 = colors[_kIndex];
      color = _ref5[0];
      freq = _ref5[1];
      if (Math.abs(freq - meanFrequency) < (stdDevFrequency * stdDeviations)) {
        _results.push([color, freq]);
      }
    }
    return _results;
  })();
  return [filteredColors, numVectors];
};
ColorAnalyzer.prototype.getPalette = function(paletteSize, exclude, error, ignoreGrey) {
  let bIsWithinError; let excludeGrey;
  let gIsWithinError; let i; let isExcluded;
  let rIsWithinError; let _i; let _ref;
  if (error === null || typeof error === 'undefined') {
    error = 0;
  }
  const pixelData = this.imageData.data;
  i = 0;
  for (_ref = pixelData.length; i < _ref; i += 4) {
    const redPixel = pixelData[i];
    const greenPixel = pixelData[i + 1];
    const bluePixel = pixelData[i + 2];
    isExcluded = false;
    if (exclude !== null && typeof exclude !== 'undefined') {
      const exRed = exclude[0];
      const exGreen = exclude[1];
      const exBlue = exclude[2];
      rIsWithinError = ((exRed - error) < redPixel && redPixel < (exRed + error));
      gIsWithinError = ((exGreen - error) < greenPixel && greenPixel < (exGreen + error));
      bIsWithinError = ((exBlue - error) < bluePixel && bluePixel < (exBlue + error));
      isExcluded = rIsWithinError && gIsWithinError && bIsWithinError;
    }
    // excludeGrey = false;
    if (ignoreGrey) {
      const redGreen = Math.abs(redPixel - greenPixel) < error;
      const redBlue = Math.abs(redPixel - bluePixel) < error;
      const greenBlue = Math.abs(greenPixel - bluePixel) < error;
      excludeGrey = ignoreGrey && redGreen && redBlue && greenBlue;
      isExcluded = isExcluded || excludeGrey;
    }
    if (!isExcluded) {
      this.octree.insertVector([redPixel, greenPixel, bluePixel]);
    }
  }
  const numVectors = this.octree.numVectors;
  return [this.octree.reduceToSize(paletteSize), numVectors];
};

const KMeans = (function() {
  /**
  KMeans constructor
  @param {number} numClusters
  @param {number} numDimensions
  */
  function KMeans(numClusters, numDimensions) {
    let i;
    this.numClusters = numClusters;
    this.clusters = (function() {
      let _i;
      const _results = [];
      i = 0;
      _i = 0;
      for (; 0 <= numClusters ? _i < numClusters : _i > numClusters; i = 0 <= numClusters ? ++_i : --_i) {
        _results.push(new Cluster(numDimensions));
      }
      return _results;
    })();
  }

  KMeans.prototype.setPoints = function(points) {
    let data; let weight;
    return this.dataPoints = (function() {
      let _i; let _len; let _ref;
      const _results = [];
      for (_i = 0, _len = points.length; _i < _len; _i++) {
        _ref = points[_i];
        data = _ref[0];
        weight = _ref[1];
        _results.push(new DataPoint(data, weight));
      }
      return _results;
    })();
  };

  KMeans.prototype.performCluster = function() {
    let movesMade;
    this.initClusters();
    while (true) {
      movesMade = this.clusterStep();
      if (movesMade === 0) {
        break;
      }
    }
    return this.clusters;
  };

  KMeans.prototype.initClusters = function() {
    let bestCenter; let cluster; let maxDist; let minDist; let point; let _i; let _jindex; let _len; let _len1; let _ref1;
    const numPoints = this.dataPoints.length;
    const firstCenterIndex = Math.floor(Math.random() * numPoints);
    this.clusters[0].addPoint(this.dataPoints[firstCenterIndex]);
    const _ref = this.clusters.slice(1);
    const _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      cluster = _ref[_i];
      maxDist = 0;
      bestCenter = null;
      _ref1 = this.dataPoints;
      for (_jindex = 0, _len1 = _ref1.length; _jindex < _len1; _jindex++) {
        point = _ref1[_jindex];
        if (point.cluster !== null && typeof point.cluster !== 'undefined') {
          continue;
        }
        // if (point.cluster == null) {
        minDist = this.nearestClusterDistance(point);
        if (minDist > maxDist) {
          maxDist = minDist;
          bestCenter = point;
        }
        // }
      }
      _results.push(cluster.addPoint(bestCenter));
    }
    return _results;
  };

  KMeans.prototype.nearestClusterDistance = function(point) {
    let cluster; let minDist; let _i; let _len;
    minDist = Number.POSITIVE_INFINITY;
    const _ref = this.clusters;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      cluster = _ref[_i];
      if ((cluster !== null && typeof cluster !== 'undefined') && cluster.size > 0) {
        minDist = Math.min(minDist, cluster.getDistanceTo(point));
      }
    }
    return minDist;
  };

  KMeans.prototype.nearestClusterTo = function(point) {
    let cluster; let distance; let nearestCluster; let nearestDistance; let _i; let _len;
    nearestCluster = null;
    nearestDistance = Number.POSITIVE_INFINITY;
    const _ref = this.clusters;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      cluster = _ref[_i];
      if (!((cluster !== null && typeof cluster !== 'undefined') && cluster.size > 0)) {
        continue;
      }
      // if ((cluster != null) && cluster.size > 0) {
      if (nearestCluster === null) {
        nearestCluster = cluster;
        nearestDistance = nearestCluster.getDistanceTo(point);
      } else {
        distance = cluster.getDistanceTo(point);
        if (distance < nearestDistance) {
          nearestCluster = cluster;
          nearestDistance = distance;
        }
      }
      // }
    }
    return nearestCluster;
  };

  KMeans.prototype.reassignPoint = function(point, cluster) {
    let wasAbleToAssign;
    const currentCluster = point.cluster;
    wasAbleToAssign = false;
    if (currentCluster === null || typeof currentCluster === 'undefined') {
      cluster.addPoint(point);
      wasAbleToAssign = true;
    } else if (currentCluster.size > 1 && cluster !== currentCluster) {
      currentCluster.removePoint(point);
      cluster.addPoint(point);
      wasAbleToAssign = true;
    }
    return wasAbleToAssign;
  };

  KMeans.prototype.clusterStep = function() {
    let nearestCluster; let numMoves; let point; let _i; let _len;
    numMoves = 0;
    const _ref = this.dataPoints;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      point = _ref[_i];
      nearestCluster = this.nearestClusterTo(point);
      if (this.reassignPoint(point, nearestCluster)) {
        numMoves++;
      }
    }
    return numMoves;
  };

  return KMeans;
})();

const DataPoint = (function() {
  /**
  DataPoint constructor
  @param {Object} data
  @param {number} weight
  @param {Object} cluster
  */
  function DataPoint(data, weight, cluster) {
    this.data = data;
    this.weight = weight || 1;// weight !== null ? weight : 1;
    this.cluster = cluster || null; // cluster !== null ? cluster : null;
  }

  return DataPoint;
})();

const Cluster = (function() {
  /**
  Cluster DS Constructor
  @param {number} numDimensions
  */
  function Cluster(numDimensions) {
    let i; let _i; let _results;
    this.size = 0;
    this.dimensions = (function() {
      _results = [];
      for (let _i = 0; 0 <= numDimensions ? _i < numDimensions : _i > numDimensions; 0 <= numDimensions ? _i++ : _i--) {_results.push(_i);}
      return _results;
    }).apply(this);
    this.sum = (function() {
      let _jindex; let _len;
      const _ref = this.dimensions;
      const _results1 = [];
      for (_jindex = 0, _len = _ref.length; _jindex < _len; _jindex++) {
        i = _ref[_jindex];
        _results1.push(0);
      }
      return _results1;
    }).call(this);
  }

  Cluster.prototype.addPoint = function(point) {
    let i; let _i; let _len;
    this.size += point.weight;
    const _ref = this.dimensions;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      i = _ref[_i];
      this.sum[i] += point.data[i] * point.weight;
    }
    return point.cluster = this;
  };

  Cluster.prototype.removePoint = function(point) {
    let i; let _i; let _len;
    point.cluster = null;
    this.size -= point.weight;
    const _ref = this.dimensions;
    const _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      i = _ref[_i];
      _results.push(this.sum[i] -= point.data[i] * point.weight);
    }
    return _results;
  };

  Cluster.prototype.getMean = function() {
    let i; let _i; let _len;
    const _ref = this.dimensions;
    const _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      i = _ref[_i];
      _results.push(this.sum[i] / this.size);
    }
    return _results;
  };

  Cluster.prototype.getDistanceTo = function(point) {
    let diff; let i; let squaredDist; let _i; let _len;
    const centroid = this.getMean();
    squaredDist = 0;
    const _ref = this.dimensions;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      i = _ref[_i];
      diff = centroid[i] - point.data[i];
      squaredDist += diff * diff;
    }
    return squaredDist;
  };

  return Cluster;
})();

const Octree = (function() {
  Octree.branching = 8;

  Octree.dimensions = 3;

  /**
  Octree constructor
  @param {number} maxBits
  */
  function Octree(maxBits) {
    let _i; let _ref; let _results;
    this.maxBits = (maxBits !== null && typeof maxBits !== 'undefined') ? maxBits : 8;
    this.leafCount = 0;
    this.numVectors = 0;
    this.reducibleNodes = new Array(this.maxBits + 1);
    this.levelMasks = (function() {
      _results = [];
      let _i = this.maxBits - 1;
      for (_ref = this.maxBits - 1; _ref <= 0 ? _i <= 0 : _i >= 0; _ref <= 0 ? _i++ : _i--) {_results.push(_i);}
      return _results;
    }).apply(this).map(function(bit) {
      return Math.pow(2, bit);
    });
    this.root = new OctreeNode(this);
  }

  Octree.prototype.isVectorEqual = function(vector1, vector2) {
    let i; let _i; let _ref;
    if ((vector1 === null || typeof vector1 === 'undefined') || (vector2 === null || typeof vector2 === 'undefined')) {
      return false;
    }
    i = 0;
    _i = 0;
    for (_ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
      if (vector1[i] !== vector2[i]) {
        return false;
      }
    }
    return true;
  };

  Octree.prototype.insertVector = function(newVect) {
    if ((this.prevNode !== null || typeof this.prevNode !== 'undefined') && (this.isVectorEqual(newVect, this.prevVect))) {
      this.prevNode.insertVector(newVect, this);
    } else {
      this.prevVect = newVect;
      this.prevNode = this.root.insertVector(newVect, this);
    }
    return this.numVectors++;
  };

  Octree.prototype.reduce = function() {
    let levelIndex;
    levelIndex = this.maxBits - 1;
    while (levelIndex > 0 && (this.reducibleNodes[levelIndex] === null || typeof this.reducibleNodes[levelIndex] === 'undefined')) {
      levelIndex--;
    }
    const node = this.reducibleNodes[levelIndex];
    this.reducibleNodes[levelIndex] = node.nextReducible;
    this.leafCount -= node.reduce();
    return this.prevNode = null;
  };

  Octree.prototype.reduceToSize = function(itemCount) {
    while (this.leafCount > itemCount) {
      this.reduce();
    }
    return this.root.getData();
  };

  return Octree;
})();

const OctreeNode = (function() {
  /**
  OctreeNode data structure constructor
  @param {Object} octree
  @param {number} level
  */
  function OctreeNode(octree, level) {
    let i;
    if (level === null || typeof level === 'undefined') {
      level = 0;
    }
    this.isLeaf = level === octree.maxBits;
    this.mean = (function() {
      let _i; let _ref;
      const _results = [];
      i = 0;
      _i = 0;
      for (_ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
        _results.push(0);
      }
      return _results;
    })();
    this.count = 0;
    if (this.isLeaf) {
      octree.leafCount++;
      this.nextReducible = null;
      this.children = null;
    } else {
      this.nextReducible = octree.reducibleNodes[level];
      octree.reducibleNodes[level] = this;
      this.children = new Array(Octree.branching);
    }
  }

  OctreeNode.prototype.insertVector = function(dataStruc, octree, level) {
    let child; let i; let _i; let _ref;
    if (level === null || typeof level === 'undefined') {
      level = 0;
    }
    if (this.isLeaf) {
      this.count++;
      i = 0;
      _i = 0;
      for (_ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
        if ((this.mean[i] === null || typeof this.mean[i] === 'undefined' ) || this.count === 1) {
          this.mean[i] = dataStruc[i];
        } else {
          this.mean[i] = (this.mean[i] * (this.count - 1) + dataStruc[i]) / this.count;
        }
      }
      return this;
    }
    const index = this.getIndex(dataStruc, level, octree);
    child = this.children[index];
    if (child === null || typeof child === 'undefined') {
      child = new OctreeNode(octree, level + 1);
      this.children[index] = child;
    }
    return child.insertVector(dataStruc, octree, level + 1);
  };

  OctreeNode.prototype.getIndex = function(dataStruc, level, octree) {
    let i; let index; let reverseIndex; let _i; let _ref;
    const shift = octree.maxBits - 1 - level;
    index = 0;
    i = 0;
    _i = 0;
    for (_ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
      reverseIndex = Octree.dimensions - 1 - i;
      index |= (dataStruc[i] & octree.levelMasks[level]) >> (shift - reverseIndex);
    }
    return index;
  };

  OctreeNode.prototype.reduce = function() {
    let child; let childSum; let i; let newCount; let nodeSum; let numChildren; let _ref; let _ref1;
    if (this.isLeaf) {
      return 0;
    }
    numChildren = 0;
    let childIndex = 0;
    let _i = 0;
    for (_ref = Octree.branching; 0 <= _ref ? _i < _ref : _i > _ref; childIndex = 0 <= _ref ? ++_i : --_i) {
      child = this.children[childIndex];
      if (child !== null && typeof child !== 'undefined') {
        newCount = this.count + child.count;
        let i = 0;
        let _jindex = 0;
        for (_ref1 = Octree.dimensions; 0 <= _ref1 ? _jindex < _ref1 : _jindex > _ref1; i = 0 <= _ref1 ? ++_jindex : --_jindex) {
          nodeSum = this.mean[i] * this.count;
          childSum = child.mean[i] * child.count;
          this.mean[i] = (nodeSum + childSum) / newCount;
        }
        this.count = newCount;
        numChildren++;
        this.children[childIndex] = null;
      }
    }
    this.isLeaf = true;
    return numChildren - 1;
  };

  OctreeNode.prototype.getData = function(dataStruc, index) {
    if (dataStruc === null || typeof dataStruc === 'undefined') {
      dataStruc = this.getData([], 0);
    } else if (this.isLeaf) {
      dataStruc.push([this.mean, this.count]);
    } else {
      let index = 0;
      let _index = 0;
      for (; 0 <= Octree.branching ? _index < Octree.branching : _index > Octree.branching; index = 0 <= Octree.branching ? ++_index : --_index) {
        if (this.children[index] !== null && typeof this.children[index] !== 'undefined') {
          dataStruc = this.children[index].getData(dataStruc, index);
        }
      }
    }
    return dataStruc;
  };

  return OctreeNode;
})();
