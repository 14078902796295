import {TopicElement} from '@talkdust/topic-room-component';
import {TopicListElement} from '/templates/top-room-list.js';

// const targetCorrolatedNodeDepth = 2;
const roomSizes = {};
const defaultGraphNodeSize = 200;
let graphYOffset;
let graphPadding;
let focusedRoomXOffset;
let focusedRoomYOffset;
let isRenderingGraph = false;
let graphResizeRecalled = false;

let trendyKey;

const colors = ['#fac9a6', '#e7ba99', '#f7931e', '#f37920', '#f47920'];


let topNodes;
let roomObjects;
let renderedRooms = [];
let roomOutlineColors = {};
let width;

window.addEventListener('load', function() {
  const isFollowPage = typeof following === 'boolean' && following === true;
  const isHomePage = typeof homePage === 'boolean' && homePage === true;
  // console.log(isFollowPage, isHomePage, !isFollowPage && !isHomePage, following)
  if (!isFollowPage && !isHomePage) {
    window.history.replaceState('state', '', `/lounge/${searchTerms}`);
  }
  if (isFollowPage) {
    initializeFollowing();
    return;
  }
  width = window.innerWidth;

  const graphContainerDOM = document.getElementsByTagName('article')[0];
  graphYOffset = graphContainerDOM.getBoundingClientRect().top + 50 + document.documentElement.scrollTop;
  focusedRoomXOffset = 350;
  focusedRoomYOffset = 450;
  graphContainerDOM.getBoundingClientRect().width/20;
  fetchGraph();
  fetchRooms();
  window.onpopstate = function(event) {
    if (event && event.state) {
      location.reload();
    } else if (event.currentTarget.location.pathname === '/') {
      location.reload();
    }
  };
});

/**
 * method for following a given topic
 * @param {string} topic - topic key to join
 */
function subscribeToTopic(topic) {
  // here we want to make an AJAX request, not a form post (page change).
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('POST', `/lounge/register_subscriber/${topic}`, true);
  xmlhttp.send();
  xmlhttp.onloadend=function() {
    const roomDOMObject = document.getElementById(topic);
    roomDOMObject.parentNode.classList.add('following');
    const subBtn = roomDOMObject.parentElement.getElementsByClassName('subscribe')[0];
    subBtn.classList.remove('subscribe');
    subBtn.classList.add('subscribed');
    subBtn.onclick = function(event) {
      event.preventDefault(); unsubscribeToTopic(topic);
    };
    subBtn.innerHTML = 'Ditch';
    const subCount = parseInt(roomDOMObject.parentElement.getElementsByClassName('subscribers')[0].innerHTML);
    roomDOMObject.parentElement.getElementsByClassName('subscribers')[0].innerHTML = subCount+1;
    showMessageDialog({heading: TEXT_ENUMS.header_good, message: TEXT_ENUMS.whatIsFollowing});
  };
  xmlhttp.onerror=function() {
    showErrorDialog({message: TEXT_ENUMS.error_following});
  };
}

/**
 * method for unfollowing a given topic
 * @param {string} topic - topic key to join
 */
function unsubscribeToTopic(topic) {
  // here we want to make an AJAX request, not a form post (page change).
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('POST', `/lounge/unregister_subscriber/${topic}`, true);
  xmlhttp.send();
  xmlhttp.onloadend=function() {
    const roomDOMObject = document.getElementById(topic);
    roomDOMObject.parentNode.classList.remove('following');
    const subBtn = roomDOMObject.parentElement.getElementsByClassName('subscribed')[0];
    subBtn.classList.remove('subscribed');
    subBtn.classList.add('subscribe');
    subBtn.onclick = function(event) {
      event.preventDefault();
      subscribeToTopic(topic);
    };
    subBtn.innerHTML = 'Follow';
    const subCount = parseInt(roomDOMObject.parentElement.getElementsByClassName('subscribers')[0].innerHTML);
    roomDOMObject.parentElement.getElementsByClassName('subscribers')[0].innerHTML = subCount-1;
    showMessageDialog({
      heading: TEXT_ENUMS.header_good,
      message: TEXT_ENUMS.whatisNotFollowing,
    });
  };
  xmlhttp.onerror=function() {
    showErrorDialog({message: TEXT_ENUMS.error_notFollowing});
  };
}

// TODO replace the following 2 calls with a single call that's executed
// once at view load, and again when nodes are clicked
/**
 * Get ranked results
 */
function fetchGraph() {
  const xmlhttp2 = new XMLHttpRequest();
  xmlhttp2.open('POST', '/lounge/crunch-results', true);
  xmlhttp2.onreadystatechange=function() {
    if (xmlhttp2.readyState == 4 && xmlhttp2.status === 200) {
      const jsonObj = JSON.parse(xmlhttp2.response);
      topNodes = jsonObj;
      initializeGraph();
    } else if (xmlhttp2.readyState === 4) {
      if (xmlhttp2.status === 403) {
        const key = encodeURI('redirect');
        const value = encodeURI(document.location.pathname);
        document.location.href=`/login.html?${key}=${value}`;
      }
    }
  };
  xmlhttp2.send(`search=${ searchTerms}`);
}

/**
 * Get all the rooms
 */
function fetchRooms() {
  globalRegex = new RegExp('^[a-f0-9]{24}$', 'g');
  const xmlhttp2 = new XMLHttpRequest();
  xmlhttp2.open('POST', '/lounge/search', true);
  xmlhttp2.onreadystatechange=function() {
    if (xmlhttp2.readyState == 4 && xmlhttp2.status === 200) {
      const jsonObj = JSON.parse(xmlhttp2.response);
      roomObjects = jsonObj.filter(roomObj => {
        if (roomObj.topic === 'meta') {
          return false;
        }
        if (globalRegex.test(roomObj.topic)) {
          return false;
        }
        return true;
      });
      initializeGraph();
    } else if (xmlhttp2.readyState === 4) {

    }
  };
  xmlhttp2.send(`search=${ searchTerms }&json=true` );
}

/**
 * Get all the rooms
 */
function fetchFollowing() {
  globalRegex = new RegExp('^[a-f0-9]{24}$', 'g');
  const xmlhttp2 = new XMLHttpRequest();
  xmlhttp2.open('POST', '/lounge/following', true);
  xmlhttp2.onreadystatechange=function() {
    if (xmlhttp2.readyState == 4 && xmlhttp2.status === 200) {
      const jsonObj = JSON.parse(xmlhttp2.response);
      roomObjects = jsonObj;
      initializeFollowing();
    } else if (xmlhttp2.readyState === 4) {

    }
  };
  xmlhttp2.send();
}


/**
 * Called once we have data for the rooms and the rankings
 */
function initializeGraph() {
  if (typeof topNodes === 'object' && roomObjects instanceof Array) {
    if (document.getElementById('loading') !== null) {
      document.getElementById('loading').remove();
    }
    if (typeof homePage === 'boolean' && homePage === true) {
      initHomeGraph();
      return;
    }

    renderedRooms = [];
    roomOutlineColors = {};

    renderedRooms.push(topNodes[searchKey]);
    topNodes[searchKey].color = getRoomColor();

    const mainNode = roomObjects.find(roomObj =>

    // TODO fix the backend to account for all the possible scenarios:
    // GET Lounge -> room created
    // POST lounge -> room created
    // GET chat -> room created (someone hard-coding URLs)
    // POST chat -> (hitting URL to create room manually)
    // Sys reboot and room not one of the seeded rooms -> pub-sub connection leads to room creation*

      roomObj.topic === searchKey);

    document.querySelector('#rooms').innerHTML = '';

    if (typeof mainNode === 'undefined') {
      setRoomDOM({
        title: searchTerms,
        chatServer: 'FIX-ME',
        dataCenter: 'LOCALHOST',
        match: true,
        active: 0,
        subscribed: 0,
        topic: searchKey,
      }, searchKey);
    } else {
      setRoomDOM({
        ...mainNode.data.connectionInfo,
        match: true,
        active: mainNode.data.rooms.map(subRoomObj => subRoomObj.activeUsers.length).reduce((accumulator, currentValue) => accumulator + currentValue),
        subscribed: mainNode.data.subscribedUsers.length,
        topic: mainNode.topic,
        rObj: mainNode,
      }, mainNode.topic);
    }
    const targetNodes = JSON.parse(JSON.stringify(topNodes[searchKey].edges)).map(edgeObj => {
      if (searchKey !== edgeObj.keys[0]) {
        return {key: edgeObj.keys[0]};
      }
      return {key: edgeObj.keys[1]};
    });

    addNewRooms(targetNodes);
    renderGraph();
  }
}

/**
 * Called once we have data for the rooms and the rankings
 */
function initializeFollowing() {
  if (followRooms instanceof Array) {
    if (document.getElementById('loading') !== null) {
      document.getElementById('loading').remove();
    }

    const container = document.querySelector('#rooms');

    followRooms.forEach(chatObj => {
      if (typeof chatObj.title !== 'string' || chatObj.title.length === 0) {
        chatObj.title = chatObj.topic;
      }

      let authed = true;
      const token = shellObj.getCookie('userToken');
      if (typeof token === 'undefined' || token === null || token === '') {
        authed = false;
      }

      const active = chatObj.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);
      const spectating = chatObj.data.rooms.map(roomObj => roomObj.spectatingUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);

      const roomEle = document.createElement('topic-room');
      roomEle.innerHTML = `
          <span slot="checkins">${chatObj.data.checkinCount}</span>
          <span slot="messages">${chatObj.data.messageCount}</span>
          <span slot="talking">${active}</span>
          <span slot="groups">${chatObj.data.rooms.length}</span>
          <span slot="spectating">${spectating}</span>
        `;

      roomEle.setAttribute('id', chatObj.topic);
      if (authed) {
        roomEle.setAttribute('data-authenticated', authed);
      }
      roomEle.setAttribute('topic', chatObj.topic);
      roomEle.setAttribute('data-server', chatObj.data.connectionInfo.chatServer);
      roomEle.setAttribute('data-center', chatObj.data.connectionInfo.dataCenter);
      roomEle.setAttribute('followers', chatObj.data.subscribedUsers.length);

      let found;
      if (chatObj.data.subscribedUsers instanceof Array) {
        found = chatObj.data.subscribedUsers
        // .map(element => JSON.parse(element))
            .find(obj => shellObj.getCookie('user') === obj.userID);
      }
      if (found) {
        roomEle.setAttribute('data-following', 'true');
      }

      if (typeof chatObj.data !== 'undefined' && typeof chatObj.data.imageIds !== 'undefined') {
        chatObj.data.imageIds.forEach(async imgId => {
          const imgData = await utilityObj.getCachedImage(imgId);
          roomEle.addBGImage(imgData);
        });
      }

      if (typeof chatObj.data !== 'undefined' && typeof chatObj.data.linkUrls !== 'undefined') {
        chatObj.data.linkUrls.forEach(async link => {
          const linkData = await utilityObj.getCachedUrlImage(link);
          roomEle.addBGImage(linkData);
        });
      }

      if (typeof chatObj.data !== 'undefined' && typeof chatObj.data.messages !== 'undefined') {
        roomEle.setAttribute('messages', JSON.stringify(chatObj.data.messages));
      }
      roomEle.setAttribute('title', chatObj.data.connectionInfo.title);
      container.appendChild(roomEle);
    });
  }
}
window.initializeFollowing = initializeFollowing;

/**
 * Initialize graph, albeit when on home screen
 */
function initHomeGraph() {
  renderedRooms = [];
  roomOutlineColors = {};

  const total = roomObjects.map(rObj => rObj.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue))
      .reduce((cumulative, nextGroup) => cumulative + nextGroup);

  roomObjects.sort(function(roomObj1, roomObj2) {
    const activeCount1 = roomObj1.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);
    const activeCount2 = roomObj2.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);

    if (activeCount1 === activeCount2) {
      const tsComp = (parseInt(roomObj2.data.activityts) || 0) - ( parseInt(roomObj1.data.activityts) || 0 );
      if (tsComp !== 0) {
        return tsComp;
      }
      if (roomObj1.topic === 'talkdust') {
        return -1;
      } else if (roomObj2.topic === 'talkdust') {
        return 1;
      } else if (roomObj1.topic === 'general') {
        return -1;
      } else if (roomObj2.topic === 'general') {
        return 1;
      } else if (roomObj1.topic === 'sports') {
        return -1;
      } else if (roomObj2.topic === 'sports') {
        return 1;
      } else if (roomObj1.topic === 'politics') {
        return -1;
      } else if (roomObj2.topic === 'politics') {
        return 1;
      } else if (roomObj1.topic === 'news') {
        return -1;
      } else if (roomObj2.topic === 'news') {
        return 1;
      } else if (roomObj1.topic === 'weather') {
        return -1;
      } else if (roomObj2.topic === 'weather') {
        return 1;
      }
      return 0;
    }
    return activeCount2 - activeCount1;
  });
  const targetNodes = [];

  roomObjects.slice(0, 6).forEach(rObj => {
    targetNodes.push({
      key: rObj.topic,
      // s: 1,
    });
  });
  addNewRooms(targetNodes);
  renderGraphHomePage();
}

/**
 * @param {Array} targetNodes - rooms to be added to the graph
 */
function addNewRooms(targetNodes) {
  const foundNodes = roomObjects.filter(roomObj =>
    targetNodes.some( targetRoomObj => roomObj.topic === targetRoomObj.key));

  const newNodes = targetNodes
      .filter(targetRoomObj => {
        const mapped = foundNodes.map(rObj => rObj.topic);
        let key = targetRoomObj.key;
        if (typeof topNodes[targetRoomObj.key] !== 'undefined') {
          key = topNodes[targetRoomObj.key].key;
        }
        return mapped.indexOf(key) === -1;
      });

  foundNodes.forEach((rObj, index) => {
    const tota = rObj.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);
    setRoomDOM({
      ...rObj.data.connectionInfo,
      active: tota,
      subscribed: rObj.data.subscribedUsers.length,
      topic: rObj.topic,
      rObj: rObj,
    }, rObj.topic);
    if (typeof topNodes[rObj.topic || rObj.data.connectionInfo.title] === 'undefined') {
      topNodes[rObj.topic || rObj.data.connectionInfo.title] = {
        edges: [],
        key: rObj.topic,
        node: rObj.topic || rObj.data.connectionInfo.title,
        rObj: rObj,
      };
    }
    renderedRooms.push(topNodes[rObj.topic || rObj.data.connectionInfo.title]);
    topNodes[rObj.topic || rObj.data.connectionInfo.title].color = getRoomColor();
  });

  newNodes.forEach((newNodeObj, index) => {
    let title = newNodeObj.key;
    let topic = newNodeObj.key;
    let rObj;
    if (typeof topNodes[newNodeObj.key] !== 'undefined') {
      title = topNodes[newNodeObj.key].node;
      topic = topNodes[newNodeObj.key].key;
      rObj = roomObjects.find(rObj => rObj.topic = topic);
    }
    setRoomDOM({
      title: title,
      active: 0,
      subscribed: 0,
      topic: topic,
      rObj: rObj,
    }, topNodes[rObj.topic].key);
    renderedRooms.push(topNodes[rObj.topic]);
    topNodes[rObj.topic].color = getRoomColor();
  });

  let mostTrendy = roomObjects.filter(rObj => rObj.topic === 'general')[0];
  if (typeof mostTrendy !== 'undefined') {
    let highest = mostTrendy.data.rooms
        .map(roomObj => roomObj.activeUsers.length)
        .reduce((accumulator, nextValue) => accumulator + nextValue);
    roomObjects.forEach(rObj => {
      const tota = rObj.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);
      if (tota > highest) {
        mostTrendy = rObj;
        highest = tota;
      }
    });

    if (!renderedRooms.some(rObj => rObj.key === mostTrendy.topic)) {
      setRoomDOM({
        ...mostTrendy.data.connectionInfo,
        active: highest,
        trendy: true,
        subscribed: mostTrendy.data.subscribedUsers.length,
        topic: mostTrendy.topic,
        rObj: mostTrendy,
      }, mostTrendy.topic);
      renderedRooms.splice(1, 0, topNodes[mostTrendy.topic || mostTrendy.data.connectionInfo.title]);
      topNodes[mostTrendy.topic || mostTrendy.data.connectionInfo.title].color = getRoomColor();
    }

    trendyKey = topNodes[mostTrendy.topic || mostTrendy.data.connectionInfo.title].key;
  } else {
    const mainNode = roomObjects.find(roomObj => roomObj.topic === searchKey);
    trendyKey = searchKey;
  }

  const roomDOMObjects = document.getElementById('rooms').children;
  const ids = [];
  for (let i = 0; i < roomDOMObjects.length; i++) {
    const roomDOM = roomDOMObjects[i];

    if (!renderedRooms.some(rObj => roomDOM.id === rObj.key)) {
      ids.push(roomDOM.id);
    }
  }
  ids.forEach(id => {
    document.getElementById(id).remove();
  });
}

/**
 * @param {object} chatObj - all information needed to instatiate web component
 * @param {string} id - target ID to either be modified, or set
 */
function setRoomDOM(chatObj, id) {
  // if (typeof chatObj.title !== 'string' || chatObj.title.length === 0) {
  //   chatObj.title = chatObj.topic;
  // }
  const rDiv = document.getElementById(id);

  if (rDiv === null) {
    let authed = true;
    const token = shellObj.getCookie('userToken');
    if (typeof token === 'undefined' || token === null || token === '') {
      authed = false;
    }

    const roomEleList = document.createElement('topic-room-list');
    roomEleList.setAttribute('id', id);
    roomEleList.setAttribute('data-title', chatObj.rObj.data.connectionInfo.title);

    // TODO this is terrible... but fastest solution right now... please fix
    if (chatObj.topic === 'arts') {
      chatObj.topic = 'the_arts';
    }
    const rooms = getSiblingRooms(chatObj.topic);
    if (rooms.length === 0) {
      return;
    }
    roomEleList.updateTopicObjects(rooms);

    if (id === 'HOME_SEARCH_BAR') {
      roomEle.style.display='none';
    }

    document.getElementById('rooms').appendChild(roomEleList);
  } else {
    if (chatObj.match) {
      rDiv.setAttribute('primary', chatObj.match);
    }
    if (chatObj.trendy) {
      rDiv.setAttribute('trendy', chatObj.trendy);
    }
  }
}

/**
@param {String} chatKey
@return {Array}
*/
function getSiblingRooms(chatKey) {
  if (typeof topNodes[chatKey] === 'undefined') {
    console.error(`issue with data used in rendering the home page for ${chatKey}:`);
    console.error(topNodes);
    return [];
  }
  const edges = topNodes[chatKey].edges;
  const roomKeys = [chatKey];
  const keyMap = {};
  keyMap[chatKey] = true;
  edges.forEach(edge => {
    if (typeof keyMap[edge.keys[0]] === 'undefined') {
      roomKeys.push(edge.keys[0]);
      keyMap[edge.keys[0]] = true;
    }
    if (typeof keyMap[edge.keys[1]] === 'undefined') {
      roomKeys.push(edge.keys[1]);
      keyMap[edge.keys[1]] = true;
    }
  });
  return roomKeys.map(roomTopic => roomObjects.find(rObj => rObj.topic == roomTopic)).filter(roomTopic => typeof roomTopic !== 'undefined'); // some rooms for w/e reason
  // do not exist so we're filtering them out
  // TODO find out why they are undefined and resolve accordingly
}

/**
generates rooms
*/
function renderGraph() {
  if (!isRenderingGraph) {
    isRenderingGraph = true;
  } else {
    graphResizeRecalled = true;
    return;
  }

  const roomDOMObjects = document.getElementById('rooms').children;

  for (const roomDOM of roomDOMObjects) {
    const color = topNodes[roomDOM.id].color;
    roomDOM.setAttribute('color', color);
  }

  // we're currently drawing edghes moment the room css animation stops, hence
  // the timeout of 1s. When we animate the edges, we can remove the delay;
  setTimeout(() => {
    isRenderingGraph = false;
    if (graphResizeRecalled) {
      renderGraph();
      graphResizeRecalled = false;
    }
  }, 1000);
}

/**
generates rooms
*/
function renderGraphHomePage() {
  if (document.getElementById('rooms') === null) {
    setTimeout(renderGraphHomePage, 50);
    return;
  }
  if (document.getElementById('trending-chat') === null) {
    const totalTalking = roomObjects.map(rObj => rObj.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue))
        .reduce((cumulative, nextGroup) => cumulative + nextGroup);
    const totalSpectators = roomObjects.map(rObj => rObj.data.rooms.map(roomObj => roomObj.spectatingUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue))
        .reduce((cumulative, nextGroup) => cumulative + nextGroup);

    // TODO filter rooms that have no active users, then set the group count
    const totalGroups = roomObjects.map(rObj => rObj.data.rooms.length)
        .reduce((cumulative, nextGroup) => cumulative + nextGroup);

    const totalMessages = roomObjects.map(rObj => parseInt(rObj.data.messageCount))
        .reduce((cumulative, nextGroup) => cumulative + nextGroup);
    const totalCheckins = roomObjects.map(rObj => parseInt(rObj.data.checkinCount))
        .reduce((cumulative, nextGroup) => cumulative + nextGroup);

    let authed = true;
    const token = shellObj.getCookie('userToken');
    if (typeof token === 'undefined' || token === null || token === '') {
      authed = false;
    }

    const topic = document.createElement('topic-room');
    // const roomEle = document.createElement('topic-room');
    topic.innerHTML = `
      <span slot="checkins">${totalCheckins}</span>
      <span slot="messages">${totalMessages}</span>
      <span slot="groups">${totalGroups}</span>
      <span slot="talking">${totalTalking}</span>
      <span slot="spectating">${totalSpectators}</span>
    `;
    topic.primary='true';
    topic.id='trending-chat';
    if (authed) {
      topic.setAttribute('data-authenticated', authed);
    }
    topic.topic='trending-chat';
    topic.setAttribute('data-server', 'chat-server0');
    topic.setAttribute('data-center', 'LOCALHOST');
    topic.followers='0';
    topic.images='[]';
    topic.title='Beta #19 - Top Room';
    topic.setAttribute('follow-status', 'undefined');
    topic.setAttribute('data-topic', 'trending-chat');
    topic.setAttribute('data-followers', '0');
    topic.setAttribute('condensed', true);
    topic.color='white';
    topic.weight='300';
  }

  if (!isRenderingGraph) {
    isRenderingGraph = true;
  } else {
    graphResizeRecalled = true;
    return;
  }
  const width = document.getElementsByTagName('body')[0].getBoundingClientRect().width - 200;

  graphYOffset = 600;// document.getElementsByTagName('article')[0].getBoundingClientRect().top + 50 + document.documentElement.scrollTop;
  graphPadding = document.getElementsByTagName('body')[0].getBoundingClientRect().width/20;
  focusedRoomXOffset = (width + 150 )* .5;
  focusedRoomYOffset = 100;// viewableHeight * .333;

  const roomDOMObjects = document.getElementById('rooms').children;

  for (const roomDOM of roomDOMObjects) {
    if (typeof topNodes[roomDOM.id] !== 'undefined') {
      const color = topNodes[roomDOM.id].color;
      roomDOM.setAttribute('color', color);
    }
  }
}

/**
 * @return {string} hexadecimal color
 */
function getRoomColor() {
  const randoColor = Math.floor(Math.random()*colors.length);
  return colors[randoColor];
}
