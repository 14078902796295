import Swiper, {Navigation, Pagination, Autpplay} from 'swiper/swiper-bundle.min.js';
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css';
// import 'swiper/modules/navigation/navigation.min.css';
// import 'swiper/modules/autoplay/autoplay.min.css';
// import 'swiper/modules/pagination/pagination.min.css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import {TopicElement} from '@talkdust/topic-room-component';

const topicRoomListStyling = document.createElement('div');
topicRoomListStyling.setAttribute('id', 'trlStyleContainer');
topicRoomListStyling.innerHTML = `
<style>
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding:  0px;
    font-family: 'roboto';
    width:initial;
    height:initial;
    width: 24.7vh;
    /* width:95%; */
    /* background-image: url(/img/waiting.svg); */
  }

  .list-title{
    display:none;
  }

  .swiper-pagination{
    pointer-events: none;
  }

  @media screen and (min-width: 580px){
    .swiper-slide {
      padding:  20px;
      width:27.7vh
    }
  }

  @media screen and (min-width: 620px){

  }

  @media screen and (min-width: 750px){
    .swiper-slide{
       margin-top: 40px;
       padding-bottom: 40px;
       width: calc(calc(35vh - 80px) * 1.62);
    }
    .list-title{
      display:block;
      left:20px;
      text-transform: uppercase;
    }
  }

  @media (min-width:1060px){
    .swiper-slide{
       padding-bottom: 0px;
    }
  }

</style>`;
const template = document.createElement('template');

template.innerHTML = `
    <h2 class="list-title" style="position:absolute;">PLACEHOLDER</h2>
    <div
      style="--swiper-navigation-color: #707070; --swiper-pagination-color: #707070;"
      class="swiper mySwiper"
    >
      <!-- <div
        class="parallax-bg"
        style="
          background-color: rgb(255, 235, 208);
          background-image: url(/img/waiting.svg);
        "
        data-swiper-parallax="-23%"
      ></div> -->
      <div class="swiper-wrapper">
          <div class="swiper-slide"><topic-room id="r1" title="r1"></topic-room></div>
          <div class="swiper-slide"><topic-room id="r2" title="r2"></topic-room></div>
          <div class="swiper-slide"><topic-room id="r3" title="r3"></topic-room></div>
          <div class="swiper-slide"><topic-room id="r4" title="r4"></topic-room></div>
          <div class="swiper-slide"><topic-room id="r5" title="r5"></topic-room></div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-pagination" ></div>
    </div>`;

/**
   * Custom Web Component for the nodes that appear on the search results page
   */
class TopicListElement extends HTMLElement {
  /**
     * Simple constructor for TopicElement
     */
  constructor() {
    super();
  }

  /**
     * baked in method that defines the attributes that matter to the international
     * workings of this component
     */
  static get observedAttributes() {
    return [
      'id',
      'data-title',
      'data-authenticated',
    ];
  }

  /**
     * Handler for changes to attributes
     * @param {string} name
     * @param {string} oldValue
     * @param {string} newValue
     */
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'data-authenticated':
        // this.authenticated = newValue;// JSON.parse(newValue);
        break;
      case 'data-title':
        this.title = newValue;
        break;
      default:
        break;
    }
    this.updateStyle();
  }

  /**
     * called everytime a component is attached to the DOM
     */
  connectedCallback() {
    // it's possible a component is connected, and by the time this method is
    // called, that it's disconected, sp we verify it is connected
    if (!this.isConnected) {
      return;
    }

    if (!document.querySelector('#trlStyleContainer')) {
      document.body.appendChild(topicRoomListStyling);
    }

    if (!this.querySelector('.swiper')) {
      this.append(template.content.cloneNode(true));
    }

    this.querySelector('.swiper-wrapper').addEventListener('touchmove', touchEvent => {
      touchEvent.stopPropagation();
    });

    this.querySelector('.mySwiper').addEventListener('touchmove', touchEvent => {
      touchEvent.stopPropagation();
    });


    if (this.topics instanceof Array) {
      this.querySelector('.swiper-wrapper').innerHTML = '';

      this.topics.forEach(topicObj => {
        const topicEle = createBaseTopic(topicObj);
        topicEle.classList.add('swiper-slide');
        this.querySelector('.swiper-wrapper').appendChild(topicEle);
      });
    }

    this.querySelector('.list-title').innerText = this.title;

    this.swiper = new Swiper(this.querySelector('.mySwiper'), {
      // slidesPerView: 1.15,
      slidesPerView: 'auto',
      // loop: true,
      breakpoints: {
        580: {
          pagination: {
            enabled: false,
          },
          // slidesPerView: 1.2,
        },
        750: {
          navigation: {
            enabled: true,
          },
          pagination: {
            enabled: false,
          },
          // slidesPerView: 2.2,
        },
        1300: {
          navigation: {
            enabled: true,
          },
          pagination: {
            enabled: false,
          },
          // slidesPerView: 3.2,
        },
        1700: {
          navigation: {
            enabled: true,
          },
          pagination: {
            enabled: false,
          },
          // slidesPerView: 4.2,
        },
      },
      // autoplay: {
      //   delay:5000,
      // },
      // speed: 600,
      // parallax: true,
      pagination: {
        el: this.querySelector('.swiper-pagination'),
        clickable: false,
        enabled: true,
      },
      navigation: {
        nextEl: this.querySelector('.swiper-button-next'),
        prevEl: this.querySelector('.swiper-button-prev'),
        enabled: false,
      },
    });

    this.updateStyle();
  }

  /**
     * takes care of modifying the dom with new values
     */
  updateStyle() {
    // if (FEATURE_TOGGLES.roomSubscriptions === true) {
    //   this.shadowRoot.querySelector('#follow').parentNode.classList.remove('toggledOff');
    // }
    // this.shadowRoot.children[0].id =this.getAttribute('id');
    // this.shadowRoot.querySelector('.talking').innerText = this.getAttribute('talkers');
    // if (this.querySelector('.talking').innerText != '0') {
    //   this.querySelector('.room_information_container').classList.add('active');
    // } else {
    //   this.querySelector('.room_information_container').classList.remove('active');
    // }
  }

  /**
  * sets the topics used to render the slider panels
  * @param {Array} jsonArrTopics - chatObj data
  */
  updateTopicObjects(jsonArrTopics) {
    this.topics = jsonArrTopics;

    if (!this.isConnected) {
      return;
    }

    this.querySelector('.swiper-wrapper').innerHTML = '';

    this.topics.forEach(topicObj => {
      const topicEle = createBaseTopic(topicObj);
      topicEle.classList.add('swiper-slide');
      this.querySelector('.swiper-wrapper').appendChild(topicEle);
    });

    this.swiper = new Swiper(this.querySelector('.mySwiper'), {
      slidesPerView: 1,
      breakpoints: {
        750: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 5000,
      },
      speed: 600,
      parallax: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'progressbar',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}

/**
   * @param {object} chatObj - all information needed to instatiate web component
   * @return {HTMLElement}
   */
function createBaseTopic(chatObj) {
  if (typeof chatObj.title !== 'string' || chatObj.title.length === 0) {
    chatObj.title = chatObj.topic;
  }

  let authed = true;
  const token = shellObj.getCookie('userToken');
  if (typeof token === 'undefined' || token === null || token === '') {
    authed = false;
  }

  const active = chatObj.data.rooms.map(roomObj => roomObj.activeUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);
  const spectating = chatObj.data.rooms.map(roomObj => roomObj.spectatingUsers.length).reduce((accumulator, nextValue) => accumulator + nextValue);

  const roomEle = document.createElement('topic-room');
  roomEle.innerHTML = `
      <span slot="checkins">${chatObj.data.checkinCount}</span>
      <span slot="messages">${chatObj.data.messageCount}</span>
      <span slot="talking">${active}</span>
      <span slot="groups">${chatObj.data.rooms.length}</span>
      <span slot="spectating">${spectating}</span>
    `;

  roomEle.setAttribute('id', chatObj.topic);
  roomEle.setAttribute('data-authenticated', authed);
  roomEle.setAttribute('topic', chatObj.topic);
  roomEle.setAttribute('data-server', chatObj.data.connectionInfo.chatServer);
  roomEle.setAttribute('data-center', chatObj.data.connectionInfo.dataCenter);
  roomEle.setAttribute('followers', chatObj.data.subscribedUsers.length);

  let found;
  if (chatObj.data.subscribedUsers instanceof Array) {
    found = chatObj.data.subscribedUsers
    // .map(element => JSON.parse(element))
        .find(obj => shellObj.getCookie('user') === obj.userID);
  }
  if (found) {
    roomEle.setAttribute('data-following', 'true');
  }

  if (typeof chatObj.data !== 'undefined' && typeof chatObj.data.imageIds !== 'undefined') {
    chatObj.data.imageIds.forEach(async imgId => {
      const imgData = await utilityObj.getCachedImage(imgId);
      roomEle.addBGImage(imgData);
    });
  }

  if (typeof chatObj.data !== 'undefined' && typeof chatObj.data.linkUrls !== 'undefined') {
    chatObj.data.linkUrls.forEach(async link => {
      const linkData = await utilityObj.getCachedUrlImage(link);
      roomEle.addBGImage(linkData);
    });
  }

  if (typeof chatObj.data !== 'undefined' && typeof chatObj.data.messages !== 'undefined') {
    roomEle.setAttribute('messages', JSON.stringify(chatObj.data.messages));
  }
  roomEle.setAttribute('title', chatObj.data.connectionInfo.title);
  // roomEle.setAttribute('follow-status', chatObj.isSubscribed);

  return roomEle;
}

export default TopicListElement;

customElements.define('topic-room-list', TopicListElement);

// </script>
